<template>
  <div class="cartWatchCustom">
    <div :class="['cartWatchCustomList', cycleCustom.flag === true && flag === true ? 'cartWatchBg' : '']">
      <div class="outerLayer">
        <el-checkbox @change="onChange" v-model="cycleCustom.flag"></el-checkbox>
      </div>
      <div class="cartImg">
        <div @click="goDetaild(cycleCustom[0])">
          <el-image :src="cycleCustom[0].product.imgPath"> </el-image>
          <div class="outStock" v-if="isSoldOut">部分<br />已售</div>
          <div class="outStock" v-if="isDelist">
            已下架
          </div>
          <div class="outStock">
            暂无现货
          </div>
        </div>
      </div>
      <div class="cartWatchCustomParameter" v-for="(item, index) in cycleCustom" :key="index">
        <div class="cartWatchCustomHead" v-if="index === 0">
          <span class="seriesModel">系列</span>
          <span class="cartNumber">款号</span>
          <span class="seriesModel">材质</span>
          <span class="seriesModel">品类</span>
          <span class="seriesModel">金重</span>
          <span class="seriesModel">表盘颜色</span>
          <span class="seriesModel">表带颜色</span>
          <span class="seriesModel">表带材质</span>
          <span class="seriesModel">机芯类型</span>
          <span class="remkQu">备注</span>
          <span class="remkQu">商品数量</span>
          <!-- <span class="seriesModel"
            >标签金额<el-popover placement="top" title="" width="200" trigger="hover" content="标签金额为预估标签金额，实际以出单金额为准。">
              <span slot="reference">
                <svg-icon iconClass="question-mark" class="question-mark"></svg-icon>
              </span> </el-popover
          ></span>
          <span class="seriesModel"
            >批发金额<el-popover placement="top" title="" width="200" trigger="hover" content="批发金额为预估批发金额，实际以出单金额为准。">
              <span slot="reference">
                <svg-icon iconClass="question-mark" class="question-mark"></svg-icon>
              </span> </el-popover
          ></span> -->
        </div>
        <div v-for="(res, idx) in item.cartList" :key="idx" :class="['dataContent', res.switchFlag === true ? 'curtBg' : '']">
          <div class="innerLayerSwitch">
            <el-checkbox
              v-if="cycleCustom[0].product.isCouples === '2'"
              v-model="res.switchFlag"
              :disabled="cycleCustom[0].totalQuantityFlag === true && res.spotFlag == 2"
              @change="innerLayer(item)"
            ></el-checkbox>
            <el-checkbox
              v-if="cycleCustom[0].product.isCouples === '1' && res.gender === '女'"
              v-model="res.switchFlag"
              :disabled="cycleCustom[0].spotFlag == 2 && cycleCustom[0].totalFemaleQuantityFlag === true && res.gender === '女'"
              @change="innerLayer(item)"
            ></el-checkbox>
            <el-checkbox
              v-if="cycleCustom[0].product.isCouples === '1' && res.gender === '男'"
              v-model="res.switchFlag"
              :disabled="cycleCustom[0].spotFlag == 2 && cycleCustom[0].totalMaleQuantityFlag === true && res.gender === '男'"
              @change="innerLayer(item)"
            ></el-checkbox>
          </div>
          <div class="seriesModel">{{ cycleCustom[0].product.categoryCodeTwo }}</div>
          <div class="cartNumber">{{ res.styleNumber }}</div>
          <div class="seriesModel">{{ res.texture }}</div>
          <div class="seriesModel">
            {{ item.product.productCategoryName }}<span v-if="res.gender">-{{ res.gender }}</span>
          </div>
          <div class="seriesModel">
            <span>{{ res.goldWeigh }}</span>
          </div>
          <div class="seriesModel">
            <span>{{ res.dialColor }}</span>
          </div>
          <div class="seriesModel">
            <span>{{ res.bandColor }}</span>
          </div>
          <div class="seriesModel">
            <span>{{ res.bandMaterial }}</span>
          </div>
          <div class="seriesModel">
            <span>{{ res.mechanicalType }}</span>
          </div>
          <div class="remkQu">
            <el-tooltip class="item" effect="dark" :content="res.productRemark" placement="bottom">
              <el-button>{{ res.productRemark }}</el-button>
            </el-tooltip>
          </div>
          <div class="remkQu reminder">
            {{ res.quantity
            }}<span
              class="tips"
              v-if="
                cycleCustom[0].spotFlag == 2 &&
                  cycleCustom[0].totalQuantityFlag === true &&
                  idx === item.cartList.length - 1 &&
                  cycleCustom[0].product.isCouples === '2'
              "
              >最少定单{{ res.leastQuantity }}件</span
            ><span
              class="tips"
              v-if="
                cycleCustom[0].spotFlag == 2 &&
                  cycleCustom[0].product.isCouples === '1' &&
                  res.gender === '女' &&
                  cycleCustom[0].totalFemaleQuantityFlag === true
              "
              >情侣款女款最少定单{{ res.leastQuantity }}件</span
            ><span
              class="tips"
              v-if="
                cycleCustom[0].spotFlag == 2 &&
                  cycleCustom[0].product.isCouples === '1' &&
                  res.gender === '男' &&
                  cycleCustom[0].totalMaleQuantityFlag === true
              "
              >情侣款男款最少定单{{ res.leastQuantity }}件</span
            >
          </div>
          <!-- <div class="seriesModel">{{ res.labelPrice }}</div>
          <div class="seriesModel">
            <span v-if="!hasPermission('展厅') || !hasPermission('加盟公司总经理')">{{ res.tradePrice }}</span>
          </div> -->
          <div class="controls-wrap">
            <!-- <div class="modify" @click="updateCart(res)">修改</div> -->
            <div class="deleteCustom" @click="deleteCart(res)">删除</div>
          </div>
          <!-- <div class="diamondSpotBtn" v-if="cycleCustom[0].spotFlag == 1 && res.quantity > 1" @click="spotList(res)">现货列表</div> -->
        </div>
      </div>
    </div>
    <div class="statusImg">
      <svg-icon v-if="cycleCustom[0].spotFlag == 2 && cycleCustom[0].isPresell !== '1'" iconClass="custom-watch" className="custom-gold"></svg-icon>
      <svg-icon v-if="cycleCustom[0].spotFlag == 1 && cycleCustom[0].isPresell !== '1'" iconClass="spot-watch" className="custom-gold"></svg-icon>
      <svg-icon v-if="cycleCustom[0].isPresell === '1'" iconClass="pre-sale-shopCart" className="pre-sale-shopCart"></svg-icon>
    </div>
    <!-- 修改复制弹窗 -->
    <UpdateWatch
      v-on="$listeners"
      v-if="watchSelectDialogVisible"
      :watchSelectDialogVisible="watchSelectDialogVisible"
      :modifyingReplication="modifyingReplication"
      @onCloseSelectDialog="onCloseSelectDialog"
      :product="cycleCustom[0].product"
    />
    <!-- 删除确认弹窗 -->
    <ConfirmDeletion
      v-if="deletingPopoversVisible"
      :deletingPopoversVisible="deletingPopoversVisible"
      @onCloseSelectDialog="onCloseSelectDialog"
      @confirmDel="confirmDel"
    />
    <!-- 手表现货弹窗 -->
    <WatchSpot
      v-on="$listeners"
      v-if="watchSpotDialogVisible"
      :watchSpotDialogVisible="watchSpotDialogVisible"
      @onCloseSelectDialog="onCloseSelectDialog"
      :modifyingReplication="modifyingReplication"
      :addOrderList="addOrderList"
    />
  </div>
</template>
<script>
import { modifyCartPublic } from '@/api/cartApi';
import UpdateWatch from '../updata/UpdateWatch.vue';
import ConfirmDeletion from '../updata/ConfirmDeletion.vue';
import WatchSpot from '../spot/WatchSpot.vue';
export default {
  name: 'CartWatchCustom',
  props: {
    cycleCustom: {
      type: Array,
      default() {
        return [];
      },
    },
    addOrderList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      modifyingReplication: {},
      watchSelectDialogVisible: false,
      watchSpotDialogVisible: false,
      deletingPopoversVisible: false,
      flag: false,
      isSoldOut: false, //是否已售
      isDelist: false, //是否下架
    };
  },
  components: {
    ConfirmDeletion,
    UpdateWatch,
    WatchSpot,
  },
  watch: {
    cycleCustom: {
      handler(newValue, oldValue) {
        this.totalQuantity();
        this.cycleCustom.forEach((res) => {
          res.cartList?.forEach((item) => {
            // item.spotFlag = false;
            // if (item.quantity > 1) {
            //   item.spotFlag = true;
            // }
          });
          this.flag = res.cartList.every((val) => val.switchFlag == true);
        });
      },
      deep: true, //深度监听数组的变化
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.totalQuantity();
      // 循环未截取的数组找到缺货下架的数据
      this.addOrderList.forEach((res) => {
        if (this.cycleCustom[0]?.id === res[0].id) {
          this.isSoldOut = res[0].cartList.some((ele) => ele.cartStatus === '已售');
          this.isDelist = res[0].cartList.some((ele) => ele.cartStatus === '下架');
        }
      });
    });
  },
  methods: {
    // 处理数据
    totalQuantity() {
      let totalQuantity = 0;
      let totalMaleQuantity = 0;
      let totalFemaleQuantity = 0;
      this.cycleCustom?.forEach((res) => {
        res.totalQuantityFlag = false;
        res.totalMaleQuantityFlag = false;
        res.totalFemaleQuantityFlag = false;
        res.cartList?.forEach((ele) => {
          // ele.spotFlag = false;
          // if (ele.quantity > 1) {
          //   ele.spotFlag = true;
          // }
          if (res.product.isCouples === '2') {
            totalQuantity = Number(totalQuantity) + Number(ele.quantity);
          } else if (res.product.isCouples === '1' && ele.gender === '女') {
            totalFemaleQuantity = Number(totalFemaleQuantity) + Number(ele.quantity);
          } else if (res.product.isCouples === '1' && ele.gender === '男') {
            totalMaleQuantity = Number(totalMaleQuantity) + Number(ele.quantity);
          }
          if ((totalQuantity >= ele.leastQuantity && !ele.gender) || ele.cartStatus !== '现货') {
            res.totalQuantityFlag = false;
          } else if (!ele.gender) {
            res.totalQuantityFlag = true;
          }
          if ((totalMaleQuantity >= ele.leastQuantity && ele.gender === '男') || ele.cartStatus !== '现货') {
            res.totalMaleQuantityFlag = false;
          } else if (ele.gender === '男') {
            res.totalMaleQuantityFlag = true;
          }
          if ((totalFemaleQuantity >= ele.leastQuantity && ele.gender === '女') || ele.cartStatus !== '现货') {
            res.totalFemaleQuantityFlag = false;
          } else if (ele.gender === '女') {
            res.totalFemaleQuantityFlag = true;
          }
          this.$forceUpdate();
        });
      });
    },
    goDetaild(item) {
      this.$router.push({
        path: '/watch',
        query: {
          id: item.product.id,
          styleNumber: item.product.styleNumber,
          generaName: item.product.generaName,
          styleFlag: item.spotFlag == 1 ? '手表现货' : '手表现货',
          classify: item.product.classify,
        },
      });
    },
    // 现货列表
    spotList(res) {
      this.watchSpotDialogVisible = true;
      this.modifyingReplication = res;
    },
    // 修改购物车
    updateCart(res) {
      this.watchSelectDialogVisible = true;
      this.modifyingReplication = res;
    },
    onCloseSelectDialog() {
      this.watchSelectDialogVisible = false;
      this.deletingPopoversVisible = false;
      this.watchSpotDialogVisible = false;
    },
    // 删除购物车数据
    deleteCart(res) {
      this.modifyingReplication = res;
      this.deletingPopoversVisible = true;
    },
    // 确认删除
    confirmDel() {
      let obj = [
        {
          id: this.modifyingReplication.id,
          sequences: this.modifyingReplication.sequenceList,
        },
      ];
      modifyCartPublic(obj, 'delete').then((res) => {
        if (res.code === 0) {
          this.$message({
            message: '删除成功',
            type: 'success',
            duration: 1500,
          });
          this.deletingPopoversVisible = false;
          this.$emit('updataCart');
        }
      });
    },
    // 外层开关
    onChange(value) {
      if (value === true) {
        this.cycleCustom.forEach((res) => {
          res.cartList.forEach((item) => {
            if (item.spotFlag === 1 && item.cartStatus === '现货') {
              item.switchFlag = true;
            } else if (item.spotFlag === 2 && item.cartStatus === '现货') {
              if (this.cycleCustom[0].product.isCouples === '2' && this.cycleCustom[0].totalQuantityFlag === false && item.cartStatus === '现货') {
                item.switchFlag = true;
              } else if (
                this.cycleCustom[0].product.isCouples === '1' &&
                item.gender === '女' &&
                this.cycleCustom[0].totalFemaleQuantityFlag === false &&
                item.cartStatus === '现货'
              ) {
                item.switchFlag = true;
              } else if (
                this.cycleCustom[0].product.isCouples === '1' &&
                item.gender === '男' &&
                this.cycleCustom[0].totalMaleQuantityFlag === false &&
                item.cartStatus === '现货'
              ) {
                item.switchFlag = true;
              } else {
                item.switchFlag = false;
              }
            } else {
              item.switchFlag = false;
            }
          });
        });
      } else {
        this.cycleCustom.forEach((res) => {
          res.cartList.forEach((item) => {
            item.switchFlag = false;
          });
        });
      }
      this.cycleCustom.forEach((res) => {
        this.flag = res.cartList.every((val) => val.switchFlag == true);
      });
      this.$forceUpdate();
    },
    //内层开关
    innerLayer(item) {
      this.cycleCustom.flag = item.cartList.every((val) => val.switchFlag == true);
      this.flag = item.cartList.every((val) => val.switchFlag == true);
    },
  },
};
</script>
<style lang="scss" scoped>
.cartWatchCustom {
  border: 1px solid #ede2ce;
  margin-top: 12px;
  font-size: 14px;
  position: relative;

  .statusImg {
    position: absolute;
    right: 0;
    width: 69px;
    height: 20px;
    top: 0;
    z-index: 0;

    .custom-gold {
      width: 73px;
      height: 20px;
    }

    .pre-sale-shopCart {
      width: 72px;
      height: 24px;
    }
  }

  .cartWatchCustomList {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;

    .outerLayer {
      margin: 10px 7px 0 10px;

      /deep/ .el-checkbox__inner {
        width: 16px;
        height: 16px;
      }

      /deep/ .el-checkbox__inner:after {
        height: 9px;
        left: 5px;
        top: 0;
        width: 4px;
      }
    }

    /deep/ .el-checkbox__inner:hover {
      border-color: #ec9368;
    }

    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #ec9368;
      border-color: #ec9368;
    }

    .cartImg {
      min-width: 70px;
      max-width: 70px;
      display: flex;
      align-items: center;
      position: relative;

      /deep/ .el-image__inner {
        cursor: pointer;
      }
    }

    .cartWatchCustomParameter {
      text-align: center;
      margin-left: 8px;

      .cartWatchCustomHead {
        display: flex;
        align-items: center;
        margin: 15px 0 0 20px;
      }

      .dataContent:last-child {
        border-bottom: none;
      }

      .dataContent {
        display: flex;
        align-items: center;
        color: #ec9368;
        border-bottom: 1px solid rgb(220, 223, 230);
        padding: 18px 0px;
        height: 34px;
        min-width: 1364px;
        position: relative;
        .innerLayerSwitch {
          width: 20px;
        }
        .controls-wrap {
          display: flex;
          position: absolute;
          right: 0;
        }
      }

      .curtBg {
        background: rgb(255, 247, 242);
      }

      .seriesModel {
        width: 114px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        white-space: break-spaces;
        word-wrap: break-word;

        span {
          height: 16px;
        }

        .el-popover__reference {
          font-size: 12px;
        }
      }

      .cartNumber {
        width: 126px;
      }

      .remkQu {
        width: 102px;

        .el-button {
          width: 100%;
          border: none;
          padding: 0;
          color: #ec9368;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .el-button:focus,
        .el-button:hover {
          background-color: transparent;
        }
      }

      .reminder {
        position: relative;

        .tips {
          color: rgb(255, 0, 0);
          position: absolute;
          left: 50%;
          top: 20px;
          transform: translate(-50%, 0);
          width: 150px;
          font-size: 12px;
        }
      }

      .modify {
        color: #00baff;
        cursor: pointer;
        min-width: 28px;
      }

      .deleteCustom {
        color: #ff0000;
        margin-left: 16px;
        cursor: pointer;
        min-width: 28px;
      }

      .diamondSpotBtn {
        width: 70px;
        text-align: center;
        height: 32px;
        line-height: 32px;
        border: 1px solid #ec9368;
        border-radius: 5px;
        cursor: pointer;
      }

      .question-mark {
        cursor: pointer;
        width: 16px;
        height: 16px;
        margin-left: 3px;
      }
    }
  }

  .cartWatchBg {
    background: rgb(255, 247, 242);
  }
}
</style>
