<template>
  <!-- 下单填写资料弹框 -->
  <div class="submitDialog">
    <el-dialog title="提交订单" :visible.sync="submitDialogVisible" :close-on-click-modal="false" :before-close="handleClose" width="900px" center>
      <div>
        <el-form ref="form" :model="form" :rules="formRules" label-width="100px">
          <el-form-item label="网点名称">
            <el-select
              @change="changeNetWork"
              :popper-append-to-body="false"
              filterable
              v-model.trim="form.netAddress"
              placeholder="请选择下单网点名称"
              value-key="orgName"
            >
              <el-option
                v-for="(item, index) in form.organizationList"
                :key="index"
                :label="item.orgCode + '-' + item.orgName"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="noOrders" v-if="tips === 'S' || tips === 'F'">{{ tips | dotState }}</div>
          <el-form-item label="联系人" prop="payerName">
            <el-input v-model="form.payerName" filterable placeholder="请输入联系人姓名"></el-input>
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input v-model="form.payerContact" maxlength="11" @input="inputPhone" filterable placeholder="请输入联系人电话"></el-input>
          </el-form-item>
          <el-form-item label="订单类型">
            <el-select :popper-append-to-body="false" v-model.trim="form.orderType2" filterable placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="form.remark" placeholder="请输入您的备注" type="textarea" :rows="7"></el-input>
          </el-form-item>
          <el-form-item class="submitBTn">
            <div style="text-align:center" :disabled="tips === 'S' || tips === 'F'" class="bottomBox">
              <el-button type="primary" :disabled="tips === 'S' || tips === 'F'" style="width: 370px; height: 52px" @click="pushData()"
                >确 定</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getOrderInfo } from '@/api/cartApi';
import { dotState } from '@/utils/filetrs';
export default {
  name: 'SubmitDialog',
  props: {
    submitDialogVisible: Boolean,
  },
  data() {
    const validateName = (_, value, callback) => {
      if (!value) {
        callback(new Error('姓名不能为空'));
      } else if (!/^[\u4e00-\u9fa5]{2,10}$/.test(value)) {
        callback(new Error('请输入有效的姓名'));
      } else {
        callback();
      }
    };
    return {
      options: [],
      form: {
        remark: '',
        netAddress: '',
        payerName: '',
        payerContact: '',
        orderType2: '',
      },
      tips: '',
      formRules: {
        payerName: [{ required: true, validator: validateName, trigger: 'blur' }],
      },
    };
  },
  filters: {
    dotState,
  },
  mounted() {
    this.getOrderInfo();
    if (!this.hasPermission('展厅')) {
      this.options = [
        { value: '公司订货-一心一爱', label: '公司订货-一心一爱' },
        { value: '公司订货-集团', label: '公司订货-集团' },
        { value: '公司订货-普货', label: '公司订货-普货' },
        { value: '新款订单', label: '新款订单' },
        { value: '潮金订单', label: '潮金订单' },
      ];
    } else {
      this.options = [
        { value: '客户订货', label: '客户订货' },
        { value: '改款订单', label: '改款订单' },
      ];
    }
  },
  methods: {
    //input事件处理
    inputPhone(e) {
      let filtered = e.replace(/[^\d]/g, '');
      if (this.form.payerContact != filtered) {
        this.form.payerContact = filtered;
      }
    },
    // 下单确定
    pushData() {
      this.$refs.form.validate((vaild) => {
        if (vaild) {
          this.$emit('placeOrder', this.form);
        }
      });
    },
    //  改变网点名称
    changeNetWork(value) {
      this.tips = value.status;
      this.form.netAddress = value.orgCode + '-' + value.orgName;
    },
    getOrderInfo() {
      getOrderInfo().then((res) => {
        if (res.code == 0) {
          this.form = { ...this.form, ...res.data };
          if (res.data?.netCode && res.data?.netAddress) {
            this.form.netAddress = res.data ? res.data.netCode + '-' + res.data.netAddress : '';
          }
          if (!this.form.organizationList || this.form.organizationList.length === 0) this.form.netAddress = null;
        }
      });
    },
    handleClose() {
      this.$emit('onCloseSelectDialog');
    },
    confirmDel() {
      this.$emit('confirmDel');
    },
  },
};
</script>
<style lang="scss" scoped>
.submitDialog {
  .el-select {
    width: 100%;
  }

  .publicClass {
    height: 64px;
    line-height: 64px;
    margin-top: 20px;
  }

  .el-form-item {
    margin-bottom: 22px !important;
  }

  .submitBTn /deep/ .el-form-item__content {
    margin-left: 0 !important;
  }

  .noOrders {
    position: absolute;
    left: 130px;
    top: 123px;
    color: red;
    font-size: 12px;
    font-weight: bold;
  }

  ::v-deep .el-select-dropdown__item {
    margin-top: 5px;
    margin-left: 10px;
    display: inline-block;
    width: 726px;
    height: 46px;
    text-align: center;
    line-height: 46px;
    border: 1px solid #d3d3d3;
    padding: 0;
    font-size: 16px;
    color: #000;
  }

  .el-select-dropdown__item.selected {
    background: #fff2ea;
    font-size: 16px;
    color: #000;
    font-weight: 400;
    border: 1px solid #ee9c75;
  }
}
</style>
