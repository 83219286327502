<template>
  <!-- 购物车确认删除弹框 -->
  <div class="getDeleteBox">
    <el-dialog title="提示" :close-on-click-modal="false" :visible.sync="deletingPopoversVisible" width="900px" center :before-close="handleClose">
      <span>确认删除购物车商品吗？</span>
      <span slot="footer" class="dialog-footer">
        <div class="cancelBtn delBtn">
          <el-button @click="handleClose">取 消</el-button>
        </div>
        <div class="confirmBtn delBtn">
          <el-button type="primary" @click="confirmDel">确 定</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'ConfirmDeletion',
  props: {
    deletingPopoversVisible: Boolean,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleClose() {
      this.$emit('onCloseSelectDialog');
    },
    confirmDel() {
      this.$emit('confirmDel');
    },
  },
};
</script>
<style lang="scss" scoped>
.getDeleteBox {
  .confirmBtn /deep/ .el-button:hover {
    width: 260px;
  }
  .confirmBtn /deep/ .el-button {
    background: #ec9368;
    color: #fff;
    font-size: 20px;
  }
  .confirmBtn /deep/ .el-button--primary {
    border: none;
  }
  .cancelBtn {
    border: 1px solid #000;
  }
  .confirmBtn {
    border: 1px solid #ec9368;
    margin-left: 40px;
  }
  .delBtn {
    width: 260px;
    height: 45px;
    display: inline-block;
    .el-button {
      width: 250px;
      height: 45px;
      cursor: pointer;
    }
  }
  .cancelBtn /deep/ .el-button:hover {
    width: 260px;
  }
  .cancelBtn /deep/ .el-button {
    background: #000;
    color: #fff;
    font-size: 20px;
    border: none;
  }
  /deep/ .el-dialog__header {
    padding: 0;
    height: 60px;
    line-height: 60px;
    background: #fff2ea;
  }
  /deep/ .el-dialog--center {
    text-align: left;
  }
  /deep/ .el-dialog__title {
    margin-left: 70px;
    font-size: 22px;
    color: #010101;
    line-height: 60px;
  }
  /deep/ .el-dialog--center {
    height: 380px;
  }
  /deep/ .el-dialog--center .el-dialog__body {
    padding: 0;
    font-size: 28px;
    text-align: center;
    color: #010101;
    margin: 78px 0 78px 0;
  }
  /deep/ .el-dialog__footer {
    padding: 0;
  }
  /deep/ .el-dialog--center .el-dialog__footer {
    text-align: center;
  }
  /deep/ .el-dialog__headerbtn .el-dialog__close {
    font-size: 24px;
    margin-right: 10px;
  }
}
</style>
