<template>
  <div v-cloak id="printBox" ref="box">
    <appheader index="1"></appheader>
    <div class="shoppingCart">
      <div class="shoppingCart_head">
        <div class="cartTitle">
          <p>我的购物车</p>
          <div class="allGoods">失效商品({{ cartNumber }})</div>
          <div class="invalidGoods" @click="goLoseEffectiveness">购物车</div>
          <div class="networkWrap">
            <el-select
              v-model="sitekey"
              placeholder="请选择网点"
              v-if="userInfo.comOrganization && userInfo.comOrganization.orgCode"
              @change="onChange"
            >
              <el-option v-for="item in networkList" :key="item.siteId" :label="item.label" :value="item.siteId"> </el-option>
            </el-select>
          </div>
        </div>
        <div style="display:flex;margin-top: 34px">
          <div style="margin-left:30px">
            <el-input
              placeholder="请输入款号"
              prefix-icon="el-icon-search"
              v-model="listQuery.styleNumber"
              @keyup.enter.native="getsearchCart()"
              clearable
            ></el-input>
          </div>
          <div class="searchClass" @click="getsearchCart()">搜索</div>
        </div>
      </div>
      <div v-if="shoppingCart.length > 0" ref="barWrap">
        <div v-for="(item, index) in shoppingCart" :key="index">
          <!-- 钻石定单 -->
          <CartDiamondCustom
            ref="cartCustom"
            @updataCart="updataCart"
            :addOrderList="addOrderList"
            v-if="item[0].product.classify == '2'"
            :cycleCustom="item"
          />
          <!-- 素金定单 -->
          <CartPlainGoldCustom
            ref="cartCustom"
            @updataCart="updataCart"
            :addOrderList="addOrderList"
            v-if="item[0].product.classify == '1'"
            :cycleCustom="item"
          />
          <!-- 手表定单 -->
          <CartWatchCustom
            ref="cartCustom"
            @updataCart="updataCart"
            :addOrderList="addOrderList"
            v-if="item[0].product.classify == '3'"
            :cycleCustom="item"
          />
          <!-- 现货足金镶嵌 -->
          <CartGoldsettingCustom
            ref="cartCustom"
            @updataCart="updataCart"
            :addOrderList="addOrderList"
            v-if="item[0].product.classify == '4'"
            :cycleCustom="item"
          />
        </div>
      </div>
      <div v-if="shoppingCart.length == 0" style="text-align:center;margin:60px 0">
        <img src="@/Img/noData.png" alt />
      </div>
      <div :class="navBarFixed == true ? 'cartNavBarWrap' : 'navBarNo'" ref="navBar">
        <div class="selectBox">
          <div class="selectBox_left">
            <div class="selectAll">
              <el-checkbox v-model="spotData" @change="spotAll"></el-checkbox>
            </div>
            <div class="selectText">现货全选</div>
          </div>
          <div class="selectBox_left" v-if="hasMainBodyType()[0].value !== '2'">
            <div class="selectAll">
              <el-checkbox v-model="customData" @change="customAll"></el-checkbox>
            </div>
            <div class="selectText">定单全选</div>
          </div>
        </div>
        <div class="modifyBox">
          <div class="modifyBox_left">
            <div class="selectAll">
              <el-checkbox v-model="checkedData" @change="ZlfAllproducts"></el-checkbox>
            </div>
            <div class="ckeckAll">全选</div>
            <div class="deleteAll" @click="showdetailbox">
              删除
            </div>
          </div>
          <!-- <div class="modifyBox_right">
            <div class="selectedNumber">
              已选<span class="selectedInterval">{{ shangp }}</span
              >件商品
            </div>
            <div class="selectedNumber">
              (现货
              <div class="selectedInterval">{{ spotNumber }}</div>
              件<span v-if="hasMainBodyType()[0].value !== '2'"
                >,定单 <span class="selectedInterval">{{ comtosNumber }}</span> <span>件</span> </span
              >)
            </div>
            <div class="selectedNumber">
              标签总金额:
              <div class="selectedInterval">
                {{ shopCarPrice }}
              </div>
            </div>
            <div class="selectedNumber" v-if="!hasPermission('展厅') || !hasPermission('加盟公司总经理')">
              批发总金额:
              <div class="selectedInterval">
                {{ wholesalePrice }}
              </div>
            </div>
            <div class="selectedNumber" v-if="totalGoldWeight && !totalCustomGoldWeight">
              现货素金总金重:
              <div class="selectedInterval">{{ totalGoldWeight }}g</div>
            </div>
            <div class="selectedNumber" v-if="totalCustomGoldWeight && !totalGoldWeight">
              定单素金总金重:
              <div class="selectedInterval">{{ totalCustomGoldWeight }}g</div>
            </div>
            <div class="selectedNumber" v-if="totalCustomGoldWeight && totalGoldWeight">
              现货+定单素金总金重:
              <div class="selectedInterval">{{ totalWeight }}g</div>
            </div>
            <div class="submitOrder" @click="submitOrder">
              提&nbsp;&nbsp;交
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <SubmitDialog
      v-if="submitDialogVisible"
      :submitDialogVisible="submitDialogVisible"
      @onCloseSelectDialog="onCloseSelectDialog"
      @placeOrder="placeOrder"
    />
    <!-- 删除确认弹窗 -->
    <ConfirmDeletion
      v-if="deletingPopoversVisible"
      :deletingPopoversVisible="deletingPopoversVisible"
      @onCloseSelectDialog="onCloseSelectDialog"
      @confirmDel="confirmDel"
    />
    <bottom ref="bottomRef"></bottom>
  </div>
</template>

<script>
import Appheader from '../moudel/Appheader';
import bottom from '../bady_bottom/bottom';
import { Decimal } from 'decimal.js';
import { cartListData, modifyCartPublic, pushOrder, getNetworkList } from '@/api/cartApi';
import CartWatchCustom from './components/custom/CartWatchCustom.vue';
import CartDiamondCustom from './components/custom/CartDiamondCustom.vue';
import CartPlainGoldCustom from './components/custom/CartPlainGoldCustom.vue';
import CartGoldsettingCustom from './components/custom/CartGoldsettingCustom.vue';
import SubmitDialog from './components/submit/SubmitDialog.vue';
import ConfirmDeletion from './components/updata/ConfirmDeletion.vue';
import { deepClone } from '@/utils/util';
import { getComputed, getHandleList } from '@/utils/computed';
import ls from '@/utils/localStorage';
export default {
  components: {
    Appheader,
    bottom,
    CartWatchCustom,
    CartDiamondCustom,
    CartPlainGoldCustom,
    SubmitDialog,
    ConfirmDeletion,
    CartGoldsettingCustom,
  },
  data() {
    return {
      noOrders: false,
      submitDialogVisible: false,
      deletingPopoversVisible: false,
      shoppingCart: [],
      addOrderList: [],
      idList: [],
      navBarFixed: false,
      listCode: [], //现货售完
      customMadeCode: [], //定单售完
      listQuery: {
        size: 9999,
        current: 1,
        styleNumber: '',
        requestFrom: 1,
        siteId: '',
        orgCode: '',
        pageType: '1',
      },
      sitekey: '',
      userInfo: {},
      networkList: [],
    };
  },
  computed: {
    // 所有全选
    checkedData: {
      get() {
        return getComputed(this.shoppingCart, 'checkedData');
      },
      set() {
        return getComputed(this.shoppingCart, 'checkedData');
      },
    },
    // 现货全选
    spotData: {
      get() {
        return getComputed(this.shoppingCart, 'spot');
      },
      set() {
        return getComputed(this.shoppingCart, 'spot');
      },
    },
    // 定单全选
    customData: {
      get() {
        return getComputed(this.shoppingCart, 'customData');
      },
      set() {
        return getComputed(this.shoppingCart, 'customData');
      },
    },
    // 全部商品
    cartNumber: function() {
      return getComputed(this.shoppingCart, 'cartNumber');
    },
    // 总商品数量
    shangp: function() {
      return getComputed(this.shoppingCart, 'shangp');
    },
    // 现货商品数量
    spotNumber: function() {
      return getComputed(this.shoppingCart, 'spotNumber');
    },
    //预定商品数量
    comtosNumber: function() {
      return getComputed(this.shoppingCart, 'comtosNumber');
    },
    // 购物车批发总金额
    wholesalePrice: function() {
      return getComputed(this.shoppingCart, 'wholesalePrice');
    },
    // 购物车标签总金额
    shopCarPrice: function() {
      return getComputed(this.shoppingCart, 'shopCarPrice');
    },
    // 购物车 素金现货总金重 spotFlag 1为现货2为定制
    totalGoldWeight: function() {
      return getComputed(this.shoppingCart, 'totalGoldWeight');
    },
    // 购物车素金订单总金重
    totalCustomGoldWeight: function() {
      return getComputed(this.shoppingCart, 'totalCustomGoldWeight');
    },
    // 现货定制素金合计
    totalWeight: function() {
      return new Decimal(this.totalGoldWeight).add(new Decimal(this.totalCustomGoldWeight));
    },
  },
  mounted() {
    this.listQuery.siteId = ls.get('userInfo').comOrganization?.id ? ls.get('userInfo').comOrganization?.id : 0;
    this.listQuery.orgCode = ls.get('userInfo').comOrganization?.orgCode ? ls.get('userInfo').comOrganization?.orgCode : 0;
    this.userInfo = ls.get('userInfo');
    this.fetchNetworkList();
    // 购物车查看
    this.getList();
    // 事件监听滚动条
    window.addEventListener('scroll', this.watchScroll);
  },
  methods: {
    onChange(value) {
      if (this.listQuery.siteId != value) {
        this.listQuery.siteId = value;
        this.getList();
      }
    },
    // 获取所有网点
    fetchNetworkList() {
      const params = {
        type: 1, // 获取不要机构的网点接口, type=1
        isAllNetwork: false, // true公司下的所有网点 false当前账号的所有网点
        isHead: false, // 周六福总部 true显示 false不显示
        typeList: 'S',
        status: 'o',
        size: 200,
        current: 1,
      };
      getNetworkList(params).then((res) => {
        res.data?.records?.forEach((item) => {
          const obj = {
            siteId: item.id,
            orgCode: item.orgCode,
            frontName: item.shortName || item.orgName,
            label: `[${item.orgCode}]${item.shortName || item.orgName}`,
          };
          this.networkList.push(obj);
        });
        if (
          this.networkList.findIndex((item) => item.siteId == ls.get('userInfo').comOrganization?.id) === -1 &&
          ls.get('userInfo').comOrganization?.id
        ) {
          this.networkList.unshift({
            siteId: ls.get('userInfo').comOrganization?.id,
            orgCode: ls.get('userInfo').comOrganization?.orgCode,
            frontName: ls.get('userInfo').comOrganization?.shortName || ls.get('userInfo').comOrganization?.orgName,
            label: `[${ls.get('userInfo').comOrganization?.orgCode}]${ls.get('userInfo').comOrganization?.shortName ||
              ls.get('userInfo').comOrganization?.orgName}`,
          });
        }
        this.sitekey = `[${this.networkList[0].orgCode}]${this.networkList[0].frontName}`;
      });
    },
    // 跳转失效商品页面
    goLoseEffectiveness() {
      this.$router.push('/shoppingCart');
    },
    // 关闭弹窗
    onCloseSelectDialog() {
      this.deletingPopoversVisible = false;
      this.submitDialogVisible = false;
      this.listQuery.current = 1;
      this.getList();
    },
    // 操作完刷新购物车列表
    updataCart() {
      this.listQuery.current = 1;
      this.getList();
    },
    // 获取列表数据
    getList() {
      cartListData(this.listQuery).then((res) => {
        this.shoppingCart = [];
        this.addOrderList = deepClone(res.data.records);
        this.shoppingCart = getHandleList(res.data.records);
        // 数据更新完成之后
        this.$nextTick(() => {
          if (this.$refs.barWrap?.getBoundingClientRect().bottom > document.documentElement.clientHeight) {
            this.navBarFixed = true;
          } else {
            this.navBarFixed = false;
          }
        });
        this.$forceUpdate();
      });
    },
    // 判断底部提交按钮距离底部的距离
    watchScroll() {
      if (this.$refs.barWrap.getBoundingClientRect().bottom > document.documentElement.clientHeight) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
    // 购物车搜索
    getsearchCart() {
      this.listQuery.current = 1;
      this.getList();
    },
    // 鼠标移出
    imgLeave() {
      this.shutDown();
    },
    // 确认删除
    confirmDel() {
      let delList = [];
      this.idList.forEach((res) => {
        let obj = {
          id: res.id,
          sequences: res.sequenceList,
        };
        delList.push(obj);
      });
      modifyCartPublic(delList, 'delete').then((res) => {
        if (res.code === 0) {
          this.$message({
            message: '删除成功',
            type: 'success',
            duration: 1500,
          });
          this.deletingPopoversVisible = false;
          this.listQuery.current = 1;
          this.getList();
        }
      });
    },
    // 购物车删除按钮
    showdetailbox() {
      this.idList = [];
      let idS = [];
      this.shoppingCart.forEach((res) => {
        res.forEach((item) => {
          item.cartList.forEach((ele) => {
            if (ele.switchFlag) {
              if (!idS.includes(ele)) {
                idS.push(ele);
              }
            }
          });
        });
      });
      this.addOrderList.forEach((res) => {
        res.forEach((item) => {
          item.cartList.forEach((ele) => {
            idS.forEach((ally) => {
              // 钻石现货
              if (ally.classify == '2' && ally.spotFlag == 1 && ally.gender) {
                if (ele.id === ally.id && ele.texture === ally.texture && ele.gender === ally.gender) {
                  if (!this.idList.includes(ele)) {
                    this.idList.push(ele);
                  }
                }
              } else if (ally.spotFlag == 1) {
                if (ele.id === ally.id) {
                  if (!this.idList.includes(ele)) {
                    this.idList.push(ele);
                  }
                }
              } else {
                if (ele.id === ally.id && ele.sequence === ally.sequence) {
                  if (!this.idList.includes(ele)) {
                    this.idList.push(ele);
                  }
                }
              }
            });
          });
        });
      });
      if (this.idList.length <= 0) {
        this.$message({
          message: '至少选择一条商品删除',
          type: 'warning',
          duration: 1500,
        });
      } else {
        this.deletingPopoversVisible = true;
      }
    },
    // 确定下单
    placeOrder(from) {
      let orderInfor = deepClone(from);
      delete orderInfor.organizationList;
      orderInfor.orderNowDtoList = [];
      // 修改bug
      if (orderInfor.netAddress?.indexOf('-') > 0) {
        orderInfor.netCode = from.netAddress.split('-')[0];
        orderInfor.netAddress = from.netAddress.split('-')[1];
      } else {
        orderInfor.netCode = '';
        orderInfor.netAddress = '';
      }
      orderInfor.sourceType = 1;
      orderInfor.orderPath = 1;
      orderInfor.requestFrom = 1;
      this.idList.forEach((res) => {
        let obj = {
          id: res.id,
          sequences: [res.sequence],
        };
        orderInfor.orderNowDtoList.push(obj);
      });
      pushOrder(orderInfor)
        .then((res) => {
          if (res.code === 0) {
            this.submitDialogVisible = false;
            this.$confirm('提交成功', '提示', {
              confirmButtonText: '继续下单',
              cancelButtonText: '查看订单',
              type: 'success',
            })
              .then(() => {
                this.getList();
                this.idList = [];
              })
              .catch(() => {
                this.idList = [];
                this.$router.push('/CommodityOrder');
              });
          }
        })
        .catch((err) => {
          if (err === '存在已售或下架商品') {
            this.submitDialogVisible = false;
            this.getList();
          }
        });
    },
    // 购物车下单按钮
    submitOrder() {
      this.idList = [];
      let idS = [];
      this.shoppingCart.forEach((res) => {
        res.forEach((item) => {
          item.cartList.forEach((ele) => {
            if (ele.switchFlag) {
              if (!idS.includes(ele)) {
                idS.push(ele);
              }
            }
          });
        });
      });
      this.addOrderList.forEach((res) => {
        res.forEach((item) => {
          item.cartList.forEach((ele) => {
            idS.forEach((ally) => {
              // 钻石现货
              if (ally.classify == '2' && ally.spotFlag == 1 && ally.gender) {
                if (ele.id === ally.id && ele.texture === ally.texture && ele.gender === ally.gender) {
                  if (!this.idList.includes(ele)) {
                    this.idList.push(ele);
                  }
                }
                // 素金现货
              } else if (ally.classify == '1' && ally.spotFlag == 1 && ally.gender) {
                if (ele.id === ally.id && ele.gender === ally.gender) {
                  if (!this.idList.includes(ele)) {
                    this.idList.push(ele);
                  }
                }
              } else if (ally.spotFlag == 1) {
                if (ele.id === ally.id) {
                  if (!this.idList.includes(ele)) {
                    this.idList.push(ele);
                  }
                }
              } else {
                if (ele.id === ally.id && ele.sequence === ally.sequence) {
                  if (!this.idList.includes(ele)) {
                    this.idList.push(ele);
                  }
                }
              }
            });
          });
        });
      });
      if (this.idList.length <= 0) {
        this.$message({
          message: '至少选择一条商品下单',
          type: 'warning',
          duration: 1500,
        });
      } else {
        this.submitDialogVisible = true;
      }
    },
    // 商品全选
    ZlfAllproducts(e) {
      if (e) {
        this.$refs.cartCustom.forEach((res) => {
          res.cycleCustom.flag = true;
          res.onChange(e);
        });
      } else {
        this.$refs.cartCustom.forEach((res) => {
          res.cycleCustom.flag = false;
          res.onChange(e);
        });
      }
      this.$forceUpdate();
    },
    // 现货全选
    spotAll(e) {
      if (e) {
        this.shoppingCart = this.shoppingCart.map((val, index) => {
          val.map((res, idx) => {
            res.cartList.forEach((ele, i) => {
              if (ele.spotFlag == 1) {
                if (ele.cartStatus === '现货') {
                  ele.switchFlag = true;
                } else {
                  ele.switchFlag = false;
                }
                val.flag = res.cartList.every((val) => val.switchFlag == true);
              }
            });
          });
          return val;
        });
      } else {
        this.shoppingCart = this.shoppingCart.map((val, index) => {
          val.map((res, idx) => {
            res.cartList.forEach((ele, i) => {
              if (ele.spotFlag == 1) {
                ele.switchFlag = false;
                val.flag = false;
              }
            });
          });
          return val;
        });
      }
      this.$forceUpdate();
    },
    // 定单全选
    customAll(e) {
      if (e) {
        this.$refs.cartCustom.forEach((res) => {
          if (res.cycleCustom[0]?.spotFlag == 2) {
            res.cycleCustom.flag = true;
            res.onChange(e);
          }
        });
      } else {
        this.$refs.cartCustom.forEach((res) => {
          if (res.cycleCustom[0]?.spotFlag == 2) {
            res.cycleCustom.flag = false;
            res.onChange(e);
          }
        });
      }
    },
  },
  // 离开页面清除监听事件
  beforeDestroy() {
    window.removeEventListener('scroll', this.watchScroll);
  },
};
</script>
<style lang="scss" scoped>
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  cursor: not-allowed !important;
  background-image: none !important;
  border: none !important;
  background: #c8c9cc !important;
  color: #fff !important;
}

.mainStoneSizeClass {
  width: 78px !important;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-right: 1px solid #ec9368;
}

#printBox {
  background: #f3f3f3;
}

.shoppingCart_head .el-input {
  display: block;
}

.shoppingCart_head ::v-deep .el-input__inner {
  border: 1px solid #ec9368;
  border-radius: 30px !important;
}

.shoppingCart_head ::v-deep .el-input__icon {
  height: 33px !important;
  line-height: 33px !important;
}

.shoppingCart {
  max-width: 1500px;
  margin: 0 auto;
  min-height: 880px;
  background: #fff;

  .invalidGoods {
    color: #fff;
    background: #ec9368;
    font-size: 14px;
    width: 100px;
    text-align: center;
    padding: 5px 0;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
  }
  .networkWrap {
    margin-left: 20px;
    .el-select {
      width: 300px;
    }
  }
  .shoppingCart_head {
    display: flex;
    justify-content: space-between;
    margin: 0 10px;
  }

  .shoppingCart_bodyFather {
    border: 1px solid #ede2ce;
    margin-top: 12px;

    .activeBack {
      background: red;
    }

    .shoppingCart_body {
      width: 100%;
      min-height: 105px;
      display: flex;
      font-size: 0;
      position: relative;

      .shoppingCart_body_child {
        display: flex;
        align-items: center;
        position: relative;

        img {
          width: 70px;
          height: 70px;
          cursor: pointer;
        }
      }

      .el-divider--horizontal {
        display: block;
        height: 1px;
        width: 100%;
        margin: 24px 0;
      }
    }
  }

  .selectBox {
    display: flex;
    align-items: center;
    margin: 30px 0 10px 0;

    .selectBox_left {
      display: flex;
      align-items: center;

      .selectText {
        color: #000000;
        margin-right: 40px;
        font-size: 20px;
      }
    }

    ::v-deep .el-checkbox__input {
      vertical-align: inherit;
    }

    ::v-deep .el-checkbox__inner {
      width: 16px !important;
      height: 16px !important;
    }

    ::v-deep .el-checkbox__inner::after {
      height: 9px !important;
      left: 5px !important;
      top: 0px !important;
      width: 3px !important;
    }

    ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #ec9368;
      border-color: #ec9368;
    }

    ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
      border-color: #dcdfe6 !important;
    }

    ::v-deep .el-checkbox__inner:hover {
      border-color: #ec9368 !important;
    }

    ::v-deep .el-checkbox__inner:focus {
      border-color: #ec9368 !important;
    }
  }

  .selectAll {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  .modifyBox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .modifyBox_left {
      display: flex;
      // font-size: 24px;
      align-items: center;
      height: 66px;

      ::v-deep .el-checkbox__input {
        vertical-align: inherit;
      }

      ::v-deep .el-checkbox__inner {
        width: 16px !important;
        height: 16px !important;
      }

      ::v-deep .el-checkbox__inner::after {
        height: 9px !important;
        left: 5px !important;
        top: 0px !important;
        width: 3px !important;
      }

      ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
      .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #ec9368;
        border-color: #ec9368;
      }

      ::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
        border-color: #dcdfe6 !important;
      }

      ::v-deep .el-checkbox__inner:hover {
        border-color: #ec9368 !important;
      }

      ::v-deep .el-checkbox__inner:focus {
        border-color: #ec9368 !important;
      }

      .ckeckAll {
        color: #000000;
        margin-right: 40px;
        font-size: 24px;
      }

      .deleteAll {
        color: #ff0000;
        font-size: 24px;
        cursor: pointer;
        z-index: 1;
      }
    }

    .modifyBox_right {
      display: flex;
      font-size: 16px;
      align-items: center;
    }

    .selectedNumber {
      display: flex;
      margin-right: 15px;
      align-items: baseline;
    }

    .selectedInterval {
      color: #ec9368;
      margin: 0 5px;
    }

    .submitOrder {
      color: #fff;
      width: 140px;
      height: 66px;
      text-align: center;
      line-height: 66px;
      background: #ec9368;
      cursor: pointer;
    }
  }
}

.el-select-dropdown__item {
  width: 97%;
}
</style>
<style scope>
.shoppingCart_head .el-input__inner {
  height: 33px !important;
  line-height: 33px !important;
}

.cartTitle {
  font-size: 24px;
  color: #000;
  margin-top: 34px;
  display: flex;
  align-items: center;
}

.allGoods {
  font-size: 22px;
  color: #ec9368;
  margin-left: 10px;
}

.cartNavBarWrap {
  position: fixed;
  bottom: 0;
  left: 50%;
  padding-bottom: 20px;
  transform: translate(-50%, 0);
  z-index: 999;
  background: #fff;
  width: 100%;
  max-width: 1480px;
  padding: 10px;
}

.navBarNo {
  max-width: 1480px;
  width: 100%;
  background: #fff;
  padding: 10px;
}

.bottomBox .el-button--primary {
  background: #ec9368 !important;
  border-color: #ec9368 !important;
}

.bottomBox .el-button--primary:focus {
  border: none !important;
}

.bottomBox {
  width: 380px;
  border: 1px solid #ec9368;
  margin: 0 auto;
}

.bottomBox .el-button--primary:hover {
  width: 380px !important;
  border-radius: 0;
}

.el-button {
  border-radius: 0 !important;
}

.searchClass {
  height: 33px;
  line-height: 33px;
  background: #ec9368;
  color: #fff;
  width: 60px;
  text-align: center;
  font-size: 16px;
  border-radius: 30px;
  margin-left: 10px;
  cursor: pointer;
}
/* 表格数据为空时显示——*/
.tableData :empty::before {
  content: '-';
}
</style>
