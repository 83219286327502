<template>
  <div class="ordinaryModify custom">
    <el-dialog :popper-append-to-body="false" :close-on-click-modal="false" :visible.sync="diamondSelectDialogVisible" :before-close="handleClose">
      <div class="ordinaryModifyChild">
        <div class="secBox">
          <div class="child" v-if="handTextureList && handTextureList.length > 0">
            <div class="selb">
              <span class="selb-title">材质：</span>
              <div class="material selectBox">
                <el-radio-group v-model="amendDuplication.texture" size="small" @change="getSizeRange($event, 'texture', 'texture')">
                  <span class="inchesFix" :class="[flagArray.textureFlag == true ? 'stockBg' : '']" v-for="item in handTextureList" :key="item">
                    <el-radio-button :value="item" :label="item">{{ item }}</el-radio-button>
                    <div class="outStore" v-if="amendDuplication.texture == item && flagArray.textureFlag == true">缺货</div>
                  </span>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="child" v-if="optionsList.gemNameList && optionsList.gemNameList.length > 0">
            <div class="selb">
              <span class="selb-title">辅石：</span>
              <div class="material selectBox">
                <el-radio-group v-model="amendDuplication.gemName" size="small" @change="getSizeRange($event, 'gemName', 'assistStoneNameOne')">
                  <span
                    class="inchesFix"
                    :class="[flagArray.gemNameFlag == true ? 'stockBg' : '']"
                    v-for="item in optionsList.gemNameList"
                    :key="item"
                  >
                    <el-radio-button :value="item" :label="item">{{ item }}</el-radio-button>
                    <div class="outStore" v-if="amendDuplication.gemName == item && flagArray.gemNameFlag == true">缺货</div>
                  </span>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="child" v-if="optionsList.formatTypes && optionsList.formatTypes.length > 0">
            <div class="selb">
              <span class="selb-title">版型：</span>
              <div class="material selectBox">
                <el-radio-group v-model="amendDuplication.formatType" size="small" @change="getSizeRange($event, 'formatType', 'formatType')">
                  <span
                    class="inchesFix"
                    :class="[flagArray.formatTypeFlag == true ? 'stockBg' : '']"
                    v-for="item in optionsList.formatTypes"
                    :key="item"
                  >
                    <el-radio-button :value="item" :label="item">{{ item }}</el-radio-button>
                    <div class="outStore" v-if="amendDuplication.formatType == item && flagArray.formatTypeFlag == true">缺货</div>
                  </span>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="child" v-if="mainStoneList && mainStoneList.length > 0">
            <div class="selb">
              <span class="selb-title">主石重量：</span>
              <div class="material selectBox">
                <el-radio-group v-model="amendDuplication.mainStone" size="small" @change="getSizeRange($event, 'mainStone', 'mainStone')">
                  <span class="inchesFix">
                    <el-radio-button v-for="item in mainStoneList" :key="item" :value="item" :label="item">{{ item }}</el-radio-button>
                  </span>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="child" v-if="optionsList.mainStoneClarity && optionsList.mainStoneClarity.length > 0">
            <div class="selb">
              <span class="selb-title">净度：</span>
              <div class="material selectBox">
                <el-radio-group
                  v-model="amendDuplication.mainStoneClarity"
                  size="small"
                  @change="getSizeRange($event, 'mainStoneClarity', 'mainStoneClarity')"
                >
                  <span
                    class="inchesFix"
                    :class="[flagArray.clarityFlag == true ? 'stockBg' : '']"
                    v-for="item in optionsList.mainStoneClarity"
                    :key="item"
                  >
                    <el-radio-button :value="item" :label="item">{{ item }}</el-radio-button>
                    <div class="outStore" v-if="amendDuplication.mainStoneClarity == item && flagArray.clarityFlag == true">缺货</div>
                  </span>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="child" v-if="optionsList.mainStoneColour && optionsList.mainStoneColour.length > 0">
            <div class="selb">
              <span class="selb-title">颜色：</span>
              <div class="material selectBox">
                <el-radio-group
                  v-model="amendDuplication.mainStoneColour"
                  size="small"
                  @change="getSizeRange($event, 'mainStoneColour', 'mainStoneColor')"
                >
                  <span
                    class="inchesFix"
                    :class="[flagArray.colourFlag == true ? 'stockBg' : '']"
                    v-for="item in optionsList.mainStoneColour"
                    :key="item"
                  >
                    <el-radio-button :value="item" :label="item">{{ item }}</el-radio-button>
                    <div class="outStore" v-if="amendDuplication.mainStoneColour == item && flagArray.colourFlag == true">缺货</div>
                  </span>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="child" v-if="handSizeList && handSizeList.length > 0">
            <div class="selb">
              <span class="inchesTips-size" v-if="handSizeList && handSizeList[0].includes('寸')">(1英寸＝2.54厘米)</span>
              <span class="selb-title">规格：</span>
              <div class="material selectBox">
                <el-radio-group v-model="amendDuplication.ringSize" size="small" @change="getSizeRange($event, 'ringSize', 'spec')">
                  <span class="inchesFix" :class="[flagArray.sizeFlag == true ? 'stockBg' : '']" v-for="item in handSizeList" :key="item">
                    <el-radio-button :value="item" :label="item">{{ item }}</el-radio-button>
                    <div class="outStore" v-if="amendDuplication.ringSize == item && flagArray.sizeFlag == true">缺货</div>
                  </span>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="child" v-if="optionsList.gia && optionsList.gia.length > 0">
            <div class="selb">
              <span class="selb-title">GIA：</span>
              <div class="material selectBox">
                <el-radio-group v-model="amendDuplication.gia" size="small" @change="getSizeRange($event, 'gia', 'gia')">
                  <span class="inchesFix" :class="[flagArray.giaFlag == true ? 'stockBg' : '']" v-for="item in optionsList.gia" :key="item">
                    <el-radio-button :value="item" :label="item">{{ item | cutFilter }}</el-radio-button>
                    <div class="outStore" v-if="amendDuplication.gia == item && flagArray.giaFlag == true">缺货</div>
                  </span>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="child">
            <div class="selb">
              <span class="selb-title">商品数量：</span>
              <el-input-number
                @keyup.enter.native="getSizeRange($event, 'quantity')"
                @input.native="onNative($event)"
                :min="product.leastQuantity"
                :max="amendDuplication.spotFlag == 2 ? 9999 : optionsList.stockQuantity"
                @change="getSizeRange($event, 'quantity')"
                v-model="amendDuplication.quantity"
              ></el-input-number>
              <span class="inchesTips" v-if="amendDuplication.spotFlag == 1">库存：{{ optionsList.stockQuantity || 0 }}件</span>
            </div>
          </div>
          <div class="child remorkBox">
            <div class="selb">
              <span class="selb-title">备注：</span>
              <el-input type="textarea" :autosize="{ minRows: 3 }" placeholder="请输入内容" v-model="amendDuplication.productRemark"> </el-input>
            </div>
          </div>
          <div class="child" v-if="amendDuplication.tradePrice && (!hasPermission('展厅') || !hasPermission('加盟公司总经理'))">
            <div class="selb">
              <span class="selb-title">批发价格：</span>
              <span>{{ amendDuplication.tradePrice }}</span>
            </div>
          </div>
          <div class="child" v-if="amendDuplication.labelPrice && (!amendDuplication.exclusivePrice || amendDuplication.exclusivePrice == 0)">
            <div class="selb">
              <span class="selb-title">标签价格：</span>
              <span>{{ amendDuplication.labelPrice }}</span>
            </div>
          </div>
          <div class="child" v-if="amendDuplication.exclusivePrice && amendDuplication.exclusivePrice != 0">
            <div class="selb">
              <span class="selb-title">尊享价格：</span>
              <span>{{ amendDuplication.exclusivePrice }}</span>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="btnFlag" class="modifyCart" @click.stop="updateCpoyCart('修改')">确定修改</el-button>
        <el-button :loading="btnFlag" class="copyCart" @click.stop="updateCpoyCart('复制')">新增商品</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getOptional, getPriceRangeSpot, getPriceRange, getSpotSpecification } from '@/api/detailsApi';
import { modifyCartPublic } from '@/api/cartApi';
import { deepClone } from '@/utils/util';
import { debounce } from '@/utils/index';
import ls from '@/utils/localStorage';
import { cutFilter } from '@/utils/filetrs';
export default {
  props: {
    diamondSelectDialogVisible: Boolean,
    modifyingReplication: {
      type: Object,
      default() {
        return {};
      },
    },
    product: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      optionsList: {
        mainStoneClarity: [],
        mainStoneColour: [],
        gia: [],
        gemNameList: [],
        formatTypes: [],
      },
      amendDuplication: {},
      btnFlag: false,
      handTextureList: [],
      handSizeList: [],
      mainStoneList: [],
      spotList: [],
      flagArray: {
        textureFlag: false,
        mainStoneFlag: false,
        sizeFlag: false,
        colourFlag: false,
        clarityFlag: false,
        giaFlag: false,
        gemNameFlag: false,
        formatTypeFlag: false,
      },
      textureIndex: 0,
    };
  },
  filters: {
    cutFilter,
  },
  mounted() {
    this.amendDuplication = deepClone(this.modifyingReplication);
    console.log(2222, this.amendDuplication);
    if (this.amendDuplication.spotFlag == 1) {
      this.getSpotOptional();
      this.getPriceRange();
    } else {
      this.getOptional();
    }
  },
  methods: {
    // 金重排序
    mainStoneSort(list) {
      // 1. 将每个区间字符串解析为对象
      const parsedRanges = list?.map((range) => {
        const [start, end] = range.split('-').map(parseFloat);
        return { start, end, range }; // 保留原始字符串以便最后恢复
      });
      // 2. 根据 start 值进行排序
      parsedRanges.sort((a, b) => a.start - b.start);
      // 3. 提取排序后的原始字符串
      return parsedRanges.map((item) => item.range);
    },
    // 获取可选属性
    getSpotOptional(gender) {
      let obj = {
        styleNumber: this.amendDuplication.styleNumber,
        classify: 2,
        spotFlag: 1,
        gender: this.amendDuplication.d,
      };
      getSpotSpecification(obj).then((res) => {
        if (!gender || gender == '女') {
          this.spotList.splice(0, this.spotList.length);
          res.data.records.forEach((item) => {
            if (!item.mainStoneClarity == null || item.mainStoneClarity == undefined) {
              item.mainStoneClarity = '';
            }
            if (item.mainStoneColor == null || item.mainStoneColor == undefined) {
              item.mainStoneColor = '';
            }
            this.spotList.push(item);
          });
          this.getParameter(this.spotList, gender);
        }
      });
    },
    getParameter(spotList, gender) {
      spotList?.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (key && key == 'texture' && item[key] && !this.handTextureList?.includes(item[key])) {
            this.handTextureList?.push(item[key]);
          }
          if (key && key == 'spec' && item[key] && !this.handSizeList?.includes(item[key])) {
            this.handSizeList?.push(item[key]);
            // 规格重新排序
            this.handSizeList?.sort(function(a, b) {
              return a - b;
            });
          }
          if (key && key == 'mainStoneWeight' && item[key] && !this.mainStoneList?.includes(item[key])) {
            this.mainStoneList?.push(item[key]);
            // 主石重新排序
            this.mainStoneList?.sort(function(a, b) {
              return a - b;
            });
          }
          if (key && key == 'mainStoneClarity' && item[key] && !this.optionsList.mainStoneClarity?.includes(item[key])) {
            this.optionsList.mainStoneClarity?.push(item[key]);
          }
          if (key && key == 'mainStoneColor' && item[key] && !this.optionsList.mainStoneColour?.includes(item[key])) {
            this.optionsList.mainStoneColour?.push(item[key]);
          }
          if (key && key == 'gia' && item[key] && !this.optionsList.gia?.includes(item[key])) {
            this.optionsList.gia?.push(item[key]);
          }
          if (key && key == 'assistStoneNameOne' && item[key] && !this.optionsList.gemNameList?.includes(item[key])) {
            this.optionsList.gemNameList?.push(item[key]);
          }
          if (key && key == 'formatType' && item[key] && !this.optionsList.formatTypes?.includes(item[key])) {
            this.optionsList.formatTypes?.push(item[key]);
          }
        });
      });
      this.optionsList.mainStoneClarity = [...new Set(this.optionsList.mainStoneClarity)];
      this.optionsList.mainStoneColour = [...new Set(this.optionsList.mainStoneColour)];
    },
    // 获取可选属性
    getOptional() {
      let obj = {
        styleNumber: this.amendDuplication.styleNumber,
        gender: this.amendDuplication.gender,
        classify: this.amendDuplication.classify,
        spotFlag: this.amendDuplication.spotFlag,
        cartId: this.amendDuplication.id,
        requestFrom: 1,
        sequence: this.amendDuplication.sequence,
      };
      getOptional(obj).then((res) => {
        this.optionsList = { ...this.optionsList, ...res.data };
        if (this.amendDuplication.isCouples == 2) {
          this.handTextureList = Object.keys(res.data.handSizeMap);
          this.mainStoneList = this.mainStoneSort(Object.keys(Object.values(res.data.handSizeMap)[0]));
          this.handSizeList = Object.values(Object.values(Object.values(res.data.handSizeMap)[0]))[0];
        } else {
          this.mainStoneList = this.mainStoneSort(Object.keys(Object.values(res.data.couplesMap[this.amendDuplication.gender])[0]));
          this.handTextureList = Object.keys(res.data.couplesMap[this.amendDuplication.gender]);
          this.handSizeList = Object.values(Object.values(res.data.couplesMap[this.amendDuplication.gender])[0])[0];
        }
        // 规格重新排序
        this.handSizeList?.sort(function(a, b) {
          return a - b;
        });
      });
    },
    getSizeRange(value, type) {
      this.amendDuplication[type] = value;
      if (this.amendDuplication.isCouples == '2') {
        if (type === 'texture') {
          this.textureIndex = this.handTextureList.indexOf(value);
          this.mainStoneList = this.mainStoneSort(Object.keys(Object.values(this.optionsList.handSizeMap)[this.handTextureList.indexOf(value)]));
          this.handSizeList = Object.values(Object.values(Object.values(this.optionsList?.handSizeMap)[this.textureIndex]))[0];
          this.amendDuplication.mainStone = '';
          this.amendDuplication.ringSize = '';
        }
        if (type === 'mainStone') {
          this.handSizeList = Object.values(Object.values(Object.values(this.optionsList?.handSizeMap)[this.textureIndex]))[
            this.mainStoneList.indexOf(value)
          ];
          this.amendDuplication.ringSize = '';
        }
      } else {
        if (type === 'texture') {
          this.textureIndex = this.handTextureList.indexOf(value);
          this.mainStoneList = Object.keys(Object.values(this.optionsList?.couplesMap[this.amendDuplication.gender])[this.textureIndex]);
          this.handSizeList = Object.values(Object.values(this.optionsList?.couplesMap[this.amendDuplication.gender])[this.textureIndex])[0];
          this.amendDuplication.mainStone = '';
          this.amendDuplication.ringSize = '';
        }
        if (type === 'mainStone') {
          this.handSizeList = Object.values(Object.values(this.optionsList?.couplesMap[this.amendDuplication.gender])[this.textureIndex])[
            this.mainStoneList.indexOf(value)
          ];
          this.amendDuplication.ringSize = '';
        }
      }
      if (this.amendDuplication.spotFlag == 1) {
        this.getPriceRange();
        this.dataProcessing(this.spotList, value, 'amendDuplication');
      } else {
        this.fetchPriceRangeCustom();
      }
    },
    dataProcessing(spotList, item, type) {
      const shopArray = [];
      shopArray.splice(0, shopArray.length);
      if (!item) {
        shopArray.push(...spotList);
      } else {
        spotList?.forEach((res) => {
          Object.keys(res).forEach((key) => {
            if (item == res[key] && key != 'accessoryStone' && key != 'assistStoneNameTwo') {
              shopArray.push(res);
            }
          });
        });
      }
      if (shopArray.length == spotList.length) {
        this.getPriceRange();
        return;
      }
      if (this[type].mainStone) {
        this.flagArray.mainStoneFlag = !shopArray.some((product) => product.mainStoneWeight === this[type].mainStone);
      }
      if (this[type].texture) {
        this.flagArray.textureFlag = !shopArray.some((product) => product.texture === this[type].texture);
      }
      if (this[type].ringSize) {
        this.flagArray.sizeFlag = !shopArray.some((product) => product.spec === this[type].ringSize);
      }
      if (this[type].mainStoneClarity) {
        this.flagArray.clarityFlag = !shopArray.some((product) => product.mainStoneClarity === this[type].mainStoneClarity);
      }
      if (this[type].mainStoneColour) {
        this.flagArray.colourFlag = !shopArray.some((product) => product.mainStoneColor === this[type].mainStoneColour);
      }
      if (this[type].gia) {
        this.flagArray.giaFlag = !shopArray.some((product) => product.gia === this[type].gia);
      }
      if (this[type].gemName) {
        this.flagArray.gemNameFlag = !shopArray.some((product) => product.assistStoneNameOne === this[type].gemName);
      }
      if (this[type].formatType) {
        this.flagArray.formatTypeFlag = !shopArray.some((product) => product.formatType === this[type].formatType);
      }
    },
    fetchPriceRangeCustom() {
      const obj = deepClone(this.amendDuplication);
      getPriceRange(obj).then((res) => {
        this.amendDuplication.tradePrice = res.data.tradePrice;
        this.amendDuplication.labelPrice = res.data.labelPrice;
        this.amendDuplication.exclusivePrice = res.data.exclusivePrice;
      });
    },
    // 复制修改商品
    updateCpoyCart(text) {
      let type = '';
      let obj = {};
      if (this.handSizeList.length > 0 && !this.amendDuplication.ringSize) {
        return this.$message({
          message: '请选择规格',
          type: 'warning',
          duration: 500,
        });
      }
      if (!this.amendDuplication.quantity) {
        return this.$message({
          message: '数量不能为0',
          type: 'warning',
          duration: 500,
        });
      }
      if (text === '修改') {
        type = 'put';
      } else {
        obj = {
          productDtoList: [this.amendDuplication],
          shopType: 1,
          type: 2,
          orderPath: 1,
          requestFrom: 1,
          spotFlag: this.amendDuplication.spotFlag,
          purchaseSource: ls.get('coludQuery') ? 0 : 1, //0 云平台产品推荐, 1 其他渠道"
          siteId: ls.get('userInfo').sysUser?.siteId || '',
        };
        type = 'post';
      }
      modifyCartPublic(text === '修改' ? this.amendDuplication : obj, type).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: text === '修改' ? '修改成功' : '复制成功',
            type: 'success',
            duration: 1500,
          });
          // 修改购物车数据
          this.$emit('updataCart');
          // 关闭弹窗
          this.$emit('onCloseSelectDialog');
        }
      });
    },
    // 实时监听 防抖debounce
    onNative: debounce(
      function(event) {
        if (Number(event.target.value)) {
          if (this.amendDuplication.spotFlag == 2 && event.target.value > 9999) {
            this.amendDuplication.quantity = 9999;
          } else if (this.amendDuplication.spotFlag == 1 && event.target.value > this.optionsList.stockQuantity) {
            this.amendDuplication.quantity = this.optionsList.stockQuantity;
          }
        }
      },
      500,
      true
    ),
    //获取价格范围
    getPriceRange() {
      this.btnFlag = true;
      getPriceRangeSpot(this.amendDuplication)
        .then((res) => {
          this.optionsList.stockQuantity = res.data.stockQuantity;
          this.btnFlag = false;
        })
        .catch(() => {
          this.btnFlag = false;
        });
    },
    handleClose() {
      this.$emit('onCloseSelectDialog');
    },
  },
};
</script>

<style scoped lang="scss">
.ordinaryModify {
  ::v-deep .el-dialog {
    width: 900px;
  }

  ::v-deep .el-dialog__body {
    padding-top: 0;
  }

  .ordinaryModifyChild {
    display: flex;
  }

  ::v-deep .el-select > .el-input {
    width: 300px;
  }

  .secBox {
    width: 100%;
  }

  .remorkBox {
    margin-top: 25px !important;
  }

  .child {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;

    .selb {
      // height: 40px;
      // line-height: 40px;
      display: flex;
      align-items: center;
      position: relative;
      // width: 400px;

      .el-radio-group {
        // margin-left: 20px;
      }

      ::v-deep .el-radio__input.is-checked .el-radio__inner {
        border-color: #ec9368;
        background: #ec9368;
      }

      ::v-deep .el-radio__input.is-checked + .el-radio__label {
        color: #ec9368;
      }

      span {
        font-size: 14px;
        display: inline-block;
        margin-right: 5px;
        min-width: 70px;
      }
      .selb-title {
        text-align: right;
      }
    }
  }

  ::v-deep .el-dialog__headerbtn .el-dialog__close {
    font-size: 24px;
  }

  ::v-deep .el-dialog__headerbtn {
    top: 5px;
    right: 5px;
  }
}

::v-deep .el-select-dropdown__item {
  padding: 0;
  height: auto;
}

::v-deep .el-select-dropdown__list {
  display: flex;
  width: 300px;
  flex-wrap: wrap;
}

::v-deep .el-input__inner {
  color: #ec9368;
}

::v-deep .el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background: #fff;
}

::v-deep .el-input-number {
  width: 300px;
}

::v-deep .el-icon-minus {
  font-size: 14px;
  color: #ec9368;
}

::v-deep .el-icon-plus {
  font-size: 14px;
  color: #ec9368;
}

::v-deep .el-textarea__inner {
  width: 760px;
}

::v-deep .el-dialog__footer {
  text-align: center;
}

::v-deep .el-button span {
  font-size: 14px;
}

.modifyCart {
  background: #87ceeb;
  color: #fff;
  border: none;
}

.copyCart {
  background: #ec9368;
  color: #fff;
  border: none;
  margin-left: 20px;
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  color: #c0c4cc;
  cursor: not-allowed;
  background-image: none;
  background-color: #fff;
  border: 1px solid #ebeef5;
}
.custom {
  .fx {
    position: relative;
    margin: 8px 0;
  }

  .fl {
    display: flex;
    align-items: center;
    position: relative;
  }

  .selectBox {
    ::v-deep .el-popper {
      left: 0 !important;
    }

    .el-scrollbar__view li {
      width: 178px;
    }
  }

  .selectBoxWatch {
    .el-scrollbar__view li {
      min-width: 90px;
    }
  }
  .inchesFix {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    .outStore {
      font-size: 12px;
      position: absolute;
      right: -1px;
      top: -1px;
      color: #999999;
    }
  }
  /deep/ .stockBg .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #c0c4cc;
    background-color: #f2f6fc;
    border-color: #f2f6fc;
  }
  .inchesTips {
    color: red;
    font-size: 12px;
    margin-left: 8px;
  }
  .inchesTips-size {
    color: red;
    font-size: 12px !important;
    margin-left: 8px;
    position: absolute;
    top: 37px;
    left: 20px;
  }
  .material {
    // width: 800px;
    ::v-deep .el-radio-button__inner {
      min-width: 65px;
    }
  }
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #fff;
    background-color: #ec9368;
    border-color: #ec9368;
    box-shadow: none;
  }
  ::v-deep .el-radio-button__inner:hover {
    color: rgb(236, 147, 104);
  }
  ::v-deep .el-radio-button__inner {
    margin: 5px;
    border-left: 1px solid #dcdfe6;
    border-radius: 4px;
  }
  ::v-deep .inchesFix:first-child .el-radio-button__inner {
    margin-left: 0;
  }
  ::v-deep .el-radio-group {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
