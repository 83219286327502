import { render, staticRenderFns } from "./CartGoldsettingCustom.vue?vue&type=template&id=f60bfe28&scoped=true"
import script from "./CartGoldsettingCustom.vue?vue&type=script&lang=js"
export * from "./CartGoldsettingCustom.vue?vue&type=script&lang=js"
import style0 from "./CartGoldsettingCustom.vue?vue&type=style&index=0&id=f60bfe28&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f60bfe28",
  null
  
)

export default component.exports